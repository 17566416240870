import { IconButton, Tooltip } from "@mui/material"
import { PropTypes } from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import Icon from "../../../../components/Icon/Icon"
import { LogFilesAuthElement } from "../../../../utils/Constants/Auth/logs"
import { ICON_NAME } from "../../../../utils/GlobalConstants"

const Action = ({ tableProps: { row }, handleRowClickActions }) => {
  const { t } = useTranslation(["logFiles"])

  return (
    <div>
      <AuthGuard actionType={ LogFilesAuthElement.DownloadAssetLog }>
        <Tooltip arrow title={ t("download") }>
          <span>
            <IconButton onClick={ () => handleRowClickActions(row?.original) } aria-label={ row?.original?.name }>
              <Icon icon={ ICON_NAME.downloadIcon } />
            </IconButton>
          </span>
        </Tooltip>
      </AuthGuard>
    </div>
  )
}

Action.propTypes = {
  handleRowClickActions: PropTypes.func,
  tableProps: PropTypes.any
}

export default Action
