import i18n from "i18next"
import React from "react"

import Action from "./Action"

import { DATE_FORMAT } from "../../../../utils/Constants/TimeZones"
import { formatDate } from "../../../../utils/helper"
import margins from "../../../../utils/styles/jsExports/marginExport.module.scss"

export const LogsTabColumnConfig = (handleRowClickActions) => [
  {
    accessorKey: "lastUpdated",
    cell: ({ cell }) => <>{ formatDate(cell.getValue(), DATE_FORMAT.dateTime) }</>,
    header: i18n.t("logFiles:table.header.lastUpdated"),
    width: margins["marginInPercentage-per15o1"]
  },
  {
    accessorKey: "name",
    header: i18n.t("logFiles:table.header.name"),
    width: margins["marginInPercentage-per13"]
  },
  {
    accessorKey: "requestType",
    header: i18n.t("logFiles:table.header.requestType"),
    width: margins["marginInPercentage-per13"]
  },
  {
    accessorKey: "size",
    header: i18n.t("logFiles:table.header.size"),
    width: margins["marginInPercentage-per13"]
  },
  {
    accessorKey: "actions",
    cell: (row) => (
      <Action tableProps={ row } handleRowClickActions={ handleRowClickActions } />
    ),
    header: i18n.t("logFiles:table.header.action"),
    width: margins["marginInPercentage-per13"]
  }
]
