/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import Header from "./internal/Header"
import { LogsTabColumnConfig } from "./internal/LogsTabelColumnConfiguration"
import {
  getRequestLogFilesPayload,
  transformAssetLogs
} from "./internal/transformAssetLogs"
import style from "./LogFiles.module.scss"

import DataTable from "../../../components/DataTable/DataTable"
import Error from "../../../components/Error/Error"
import Loading from "../../../components/Loading/Loading"
import { useAssetViewContext } from "../../../contexts/assetView/assetView"
import { useToaster } from "../../../contexts/ToastContext"
import { fileSVC, fileSVCKeys } from "../../../services/reactQueries/filesvc"
import { DEFAULT_DATE_RANGE } from "../../../utils/Constants/AssetLogs"
import { TABLE_INSTANCES } from "../../../utils/Constants/DataTable"
import {
  useLazyQuery,
  useMutationWithHandlers
} from "../../../utils/CustomHooks/reactQuery"
import { GLOBAL_THROTTLE_TIME } from "../../../utils/GlobalConstants"
import { throttle } from "../../../utils/helper"

const LogFiles = () => {
  const { t } = useTranslation(["logFiles"])
  const {
    assetDetails: { serialNumber }
  } = useAssetViewContext()

  const { displayMediumErrorToast } = useToaster()

  const [assetLogsDateRange, setAssetLogsDateRange] = useState({
    endDate: DEFAULT_DATE_RANGE.endDate,
    minDate: DEFAULT_DATE_RANGE.sixMonthStartDate,
    startDate: DEFAULT_DATE_RANGE.startDate
  })
  const queryKey = [fileSVCKeys.GET_ASSET_CONFIG, serialNumber]

  const [getLogFiles, { isLoading, data, isError, refetch }] = useLazyQuery(
    queryKey,
    () => fileSVC.getAssetLogFiles(serialNumber, assetLogsDateRange)
  )

  const logFiles = data?.data

  const { requestMutation: handleRequestLogFiles } = useMutationWithHandlers({
    queryFn: fileSVC.requestLogFiles,
    refetchQueries: [
      { id: serialNumber, key: fileSVCKeys.GET_ASSET_LOG_FILES }
    ]
  })

  const onPreSignedURLRequestSuccess = (data) => {
    const url = data?.data?.url
    if (url) {
      window.open(url, "_blank")
    }else {
      displayMediumErrorToast(t("downloadLogFile.error"))
    }
  }

  const onPreSignedURLRequestError = () => {
    displayMediumErrorToast(t("downloadLogFile.error"))
  }

  const { requestMutation: handlePreSignedURLRequest } =
    useMutationWithHandlers({
      onCompletedCallback: onPreSignedURLRequestSuccess,
      onErrorCallback: () => onPreSignedURLRequestError(),
      queryFn: fileSVC.getDownloadURL
    })

  useEffect(() => {
    getLogFiles()
  }, [assetLogsDateRange])

  const handleRefresh = () => refetch()

  const handleRowClickActions = (payload) => {
    handlePreSignedURLRequest({
      payload: {
        fileId: payload.id
      }
    })
  }

  const throttleActions = useCallback(
    throttle(handleRowClickActions, GLOBAL_THROTTLE_TIME),
    []
  )

  const handleRequestLogsFile = () => {
    handleRequestLogFiles({
      errorMessage: t("requestLogFilesMessage.error"),
      payload: getRequestLogFilesPayload(serialNumber),
      successMessage: t("requestLogFilesMessage.success")
    })
  }

  if (isLoading)
    return (
      <div className={ style.loader }>
        <Loading />
      </div>
    )
  if (isError)
    return <Error error={ { message: t("logsApiError.errorMessage") } } />

  return (
    <div className={ style.logFiles }>
      <Header
        refreshConfigs={ handleRefresh }
        handleRequestLogsFile={ handleRequestLogsFile }
        assetLogsDateRange={ assetLogsDateRange }
        setAssetLogsDateRange={ setAssetLogsDateRange }
      />
      <div className={ style.tableSection }>
        <DataTable
          queryKey = { queryKey }
          type={ TABLE_INSTANCES.ASSET_LOGS }
          tableData={ transformAssetLogs(logFiles) }
          columnsData={ LogsTabColumnConfig(throttleActions) }
        />
      </div>
    </div>
  )
}

export default LogFiles
