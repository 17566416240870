import { Button, IconButton, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import AuthGuard from "../../../../components/AuthGuard/AuthGuard"
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker"
import Icon from "../../../../components/Icon/Icon"
import {
  ASSET_LOGS_BUTTONS,
  CONFIG_ACTIONS
} from "../../../../utils/Constants/Logs"
import styles from "../LogFiles.module.scss"

const Header = ({
  assetLogsDateRange,
  setAssetLogsDateRange,
  handleRequestLogsFile,
  refreshConfigs
}) => {
  const { t } = useTranslation(["asset"])

  const handleClick = (buttonType) => {
    if (buttonType === CONFIG_ACTIONS.REFRESH.key) {
      refreshConfigs()
    } else {
      handleRequestLogsFile()
    }
  }

  return (
    <div className={ styles.buttonGroup }>
      { ASSET_LOGS_BUTTONS.map((button) => (
        <AuthGuard key={ button.id } actionType={ button.accessKey }>
          <Button
            key={ button.id }
            variant="text"
            color="secondary"
            startIcon={ <Icon icon={ button.icon } /> }
            aria-label={ t(`logs.buttons.${button.name}`) }
            onClick={ () => handleClick(button.name) }
          >
            { t(`logs.buttons.${button.name}`) }
          </Button>
        </AuthGuard>
      )) }
      <DateRangePicker
        minDate={ assetLogsDateRange?.minDate }
        startDate={ assetLogsDateRange?.startDate }
        endDate={ assetLogsDateRange?.endDate }
        onChange={ (value) =>
          setAssetLogsDateRange((prevState) => ({ ...prevState, ...value }))
        }
      />
      <Tooltip
        arrow
        title={ <span>{ t("logs.tooltip") }</span> }
        classes={ { tooltip: "configActionTooltip" } }
      >
        <IconButton style={ { marginLeft: "-12px" } }>
          <Icon icon="about" />
        </IconButton>
      </Tooltip>
    </div>
  )
}

Header.propTypes = {
  assetLogsDateRange: PropTypes.object,
  handleRequestLogsFile: PropTypes.func,
  refreshConfigs: PropTypes.func,
  setAssetLogsDateRange: PropTypes.func
}

export default Header
