import { Tab, Tabs } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./AssetTabs.module.scss"

import TabPanel from "../../../components/TabPanel/TabPanel"
import { AssetAuthElement } from "../../../utils/Constants/Auth/asset"
import { ConfigAuthElement } from "../../../utils/Constants/Auth/configuration"
import { FaultsAuthElement } from "../../../utils/Constants/Auth/faults"
import { LogFilesAuthElement } from "../../../utils/Constants/Auth/logs"
import { MetricsAssetAuthElement } from "../../../utils/Constants/Auth/metrics"
import { SoftwareAuthElement } from "../../../utils/Constants/Auth/software"
import useAccess from "../../../utils/CustomHooks/useAccess"
import AssetFaults from "../AssetFaults/AssetFaults"
import Configuration from "../Configuration/Configuration"
import LogFiles from "../LogFiles/LogFiles"
import Properties from "../Properties/Properties"
import SoftwarePackages from "../SoftwarePackages/SoftwarePackages"
import Status from "../Status/Status"

const AssetTabs = () => {
  const [value, setValue] = React.useState(0)
  const { t } = useTranslation(["asset"])
  const hasFaultAccess = useAccess(FaultsAuthElement.ReadAssetFault)
  const hasPropertiesAccess = useAccess(
    AssetAuthElement.Properties.ReadAssetProperties
  )
  const hasMetricsAccess = useAccess(MetricsAssetAuthElement.ReadAssetMetrics)
  const hasConfigAccess = useAccess(ConfigAuthElement.ReadAssetConfigTab)
  const hasSWAccess = useAccess(SoftwareAuthElement.ReadAssetsSoftware)
  const hasLogsAccess = useAccess(LogFilesAuthElement.ReadAssetLogs)

  const tabs = [
    {
      component: <AssetFaults />,
      hasAccess: hasFaultAccess,
      id: 1,
      label: t("tabs.assetFaults")
    },
    {
      component: <Properties />,
      hasAccess: hasPropertiesAccess,
      id: 2,
      label: t("tabs.properties")
    },
    {
      component: <Status />,
      hasAccess: hasMetricsAccess,
      id: 3,
      label: t("tabs.status")
    },
    {
      component: <Configuration />,
      hasAccess: hasConfigAccess,
      id: 4,
      label: t("tabs.configurations")
    },
    {
      component: <LogFiles />,
      hasAccess: hasLogsAccess,
      id: 5,
      label: t("tabs.logs")
    },
    {
      component: <SoftwarePackages />,
      hasAccess: hasSWAccess,
      id: 6,
      label: t("tabs.swPackages")
    }
  ]

  return (
    <>
      <div className={ styles.root }>
        <Tabs
          className={ styles.tabsContainer }
          value={ value }
          onChange={ (_event, newValue) => setValue(newValue) }
        >
          { tabs
            .filter((tab) => tab.hasAccess)
            .map((tab, index) => (
              <Tab key={ tab.id } label={ tab.label } { ...a11yProps(index) } />
            )) }
        </Tabs>
      </div>
      { tabs
        .filter((tab) => tab.hasAccess)
        .map((tab, index) => (
          <TabPanel
            key={ tab.id }
            className={ styles.tabPanel }
            value={ value }
            index={ index }
          >
            { tab.component }
          </TabPanel>
        )) }
    </>
  )
}

AssetTabs.propTypes = {
  deviceName: PropTypes.string,
  deviceNumber: PropTypes.string,
  muted: PropTypes.bool
}

export default AssetTabs

const a11yProps = (index) => {
  return {
    "aria-controls": `asset-tabpanel-${index}`,
    id: `asset-tab-${index}`
  }
}
